/* ##remfixer: 1080 */

.Home {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 0.777778rem;
}

.content {
  width: 100%;
  overflow: hidden;
}

.content .searchDiv {
  margin: 0.277778rem 0.444444rem 0.037037rem 0.444444rem;
  width: 9.111111rem;
  height: 0.833333rem;
  overflow: hidden;
}

.content .searchDiv::before {
  content: " ";
  background: url(../assets/images/ic_search.png) no-repeat center;
  position: relative;
  display: inline-block;
  background-size: cover;
  vertical-align: middle;
  width: 0.388889rem;
  height: 0.388889rem;
  right: -0.296296rem;
  font-size: 0.388889rem;
}

.content .searchDiv .searchInput {
  width: 100%;
  height: 0.833333rem;
  font-size: 0.388889rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 0.925926rem;
  margin-left: -0.388889rem;
}

.content .searchDiv .searchSpan {
  opacity: 0.4;
  position: relative;
  width: 7.611111rem;
  left: 0.888889rem;
  top: -0.62963rem;
  display: inline-block;
  font-family: "FlymeRegular";
  font-size: 0.388889rem;
  color: #000000;
  text-align: left;
  line-height: 0.444444rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}